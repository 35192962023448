.custom-box-shadow {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
  }
  input[type="file"] {
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    color: #5F5F5F;
    background-color: #F8FCFF;
    appearance: none; /* Remove default file input styles */
}

input[type="file"]:hover {
    border-color: #4c69dd;
}
