/* Overlay styles */
.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 16px;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  
  .show-overlay {
    transform: translateY(0);
    opacity: 1;
  }
  
  .overlay-title, .overlay-description {
    opacity: 0;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .overlay.show-overlay .overlay-title {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
  }
  
  .overlay.show-overlay .overlay-description {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.7s;
  }
  
  .product {
    transition: all 0.5s ease; /* Smooth transition for resizing */
  }
  