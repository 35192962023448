.slider-container-Client .slick-slide {
    padding: 0 12px; 
  }

  /* .slider-container-Client {
    width: 100%;
  } */
  
  .slider-container-Client .slick-list {
    margin: 0 32px; 
    
  }

  .slick-list {
    padding-top: 40px;
    height: 400px;
  }


  @media (max-width: 768px) {

    .slider-container-Client .slick-slide {
      padding: 0 12px; 
    }
    
    .slider-container-Client .slick-list {
      margin: 0 0px; 
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInText {
    animation: fadeIn 1s ease-out forwards;
  }
  
  .fadeInDelay {
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 0.5s; 
  }
  
  .fadeInLongDelay {
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 1s; 
  }
  .fullBoxShadow {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
  }
  