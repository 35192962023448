/* Add these styles to your CSS file */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .fade-in.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Hover Effect */
  .fade-in:hover {
    transform: scale(1.02); /* Slightly increase the size */
    transition: transform 0.5s ease; /* Smooth transition for hover */
  }
  