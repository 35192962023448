.newsdetailpage-carousel .slick-list {
    width: 70%;
}

@media (max-width: 1024px) { /* Adjusts for mobile screens (640px and below) */
    .newsdetailpage-carousel .slick-list {
        width: 100%;
    }

    .newsdetailpage-carousel .slick-slider {
        margin-top: -16px;
    }
}