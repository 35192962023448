/* From Uiverse.io by alexmaracinaru */ 
.btn {
    cursor: pointer;
 
  

  

  
    display: flex;
    align-items: center;
 
  }
  
 
  
  .btn > .Btnarrow {
    width: 34px;
 
    transition: transform 0.3s ease-in-out;
  }
  
  .btn:hover .Btnarrow {
    transform: translateX(5px);
  }
  
  .btn:active {
    transform: scale(0.95);
  }
  