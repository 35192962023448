.marquee {
    display: flex;
    overflow: hidden;
}

.marquee-content {
    display: flex;
    animation: scroll 35s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
