.slick-list {
    padding-bottom: 80px;
}

@media (max-width: 640px) { /* Adjust max-width if needed */
    .slick-list {
        padding-bottom: 40px;
    }
}
.btn:hover .Btnarrow {
    transform: translateX(5px);
  }
  